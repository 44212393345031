// Library Imports
import React, { Component } from "react";
import $ from "jquery";
import ReactHTMLParser from "react-html-parser";
// Service Imports
import { LoginService } from "../../services/login.service";
import { FetchNotificationService } from "../../services/fetchnotification";
// Other Imports
import { warningNotification, warning } from '../../components/notification/notifications';
// Asset Imports
const Finxlogo = require('../../assets/images/dapIcon.png');

//! Unused Imports
// import Finxlogo from "../../assets/images/dapIcon.png";

class Adminheader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertcount: '',
      notificationcount: '',
      // isPopoverOpen: true,
      //checkedtab : false;	
      flipalert: false,
      alertNotificationEvents: JSON.parse(localStorage.getItem('AdminAlertNotification')),
      //notificationEvents: JSON.parse(localStorage.getItem('AdminNotification')),
      inboxCount:""
    }
    //localStorage.setItem('checkedtab',false);
  }
  changepassword = () => {
    this.props.history.push("/changepassword", {
      alerts: this.state.alertNotificationEvents,
      notification: this.state.notificationEvents
    })
  };
  componentDidMount() {
    let partyID = localStorage.getItem("ciel-userid-Token");
    FetchNotificationService.fetchNotification("partyId=" + partyID, (res1) => {
      this.setState({
        notificationEvents: res1.responseListObject
      })
    })

    let senderPartyId = localStorage.getItem("ciel-userid-Token");
    FetchNotificationService.getCount("senderPartyId=" + senderPartyId, (res1) => {
      // console.log("data inbox:: ", this.state.inboxCount)
       this.setState({
         inboxCount: res1.responseObject.unreadCount
       })
       console.log("data inbox:: ", this.state.inboxCount)
       //alert("sneha"+ this.state.inboxCount)
     })

    $(window).blur(function () {
      window.addEventListener('storage', e => {
        if (localStorage.getItem('userid-Token') === null && localStorage.getItem('login') === null) {
          if (localStorage.getItem("windowloggedout") === false) {
            //localStorage.removeItem("windowloggedout");
            //window.location.href = '/';
          } else {
            localStorage.clear();
            window.location.href = '/';
          }
        }
        e.currentTarget.removeEventListener("storage", e)
      });
    });
    if (localStorage.getItem('userid-Token') !== null && localStorage.getItem('login') !== null) {
      // if (localStorage.getItem('roleId') == 5) {
      if ([5,"5"].includes(localStorage.getItem('roleId'))) {
        //window.location.href = '/learnerDashboard';
        if (localStorage.getItem('checkedtab') === false) {
          localStorage.setItem('checkedtab', true);
          window.location.replace('/learnerDashboard');
        }
      }
      else {
        //window.location.href = '/admin-dashboard';

        if (localStorage.getItem('checkedtab') === false) {
          localStorage.setItem('checkedtab', true);
          window.location.replace('/admin-dashboard');

        }

      }

    }
    else {

      // if (localStorage.getItem('checkedtab') == 'false') {
      if (['false',"false",false].includes(localStorage.getItem('checkedtab'))) {
        localStorage.setItem('checkedtab', false);
        window.location.href = '/';

      }
    }
    var currpage = window.location.href;
    // if (currpage.substring(currpage.lastIndexOf('/') + 1) == 'admin-user-list') {
    if (['admin-user-list',"admin-user-list"].includes(currpage.substring(currpage.lastIndexOf('/') + 1))) {
      this.setState({
        flipalert: true,
      })

    }
    $(document).ready(function () {
      $(".popup").click(function () {
        $("#myPopup").toggleClass("show");
        setTimeout(function () {
          $('#myPopup').toggleClass('show');
        }, 2000)
      });

      $(".popup1").click(function () {
        $("#myPopup1").toggleClass("show");
        setTimeout(function () {
          $('#myPopup1').toggleClass('show');
        }, 2000)
      });

      $(".popup2").click(function () {
        $("#myPopup2").toggleClass("show");
        setTimeout(function () {
          $('#myPopup2').toggleClass('show');
        }, 2000)
      });

      $(".popup3").click(function () {
        $("#myPopup3").toggleClass("show");
        setTimeout(function () {
          $('#myPopup3').toggleClass('show');
        }, 2000)
      });

      $(".popup4").click(function () {
        $("#myPopup4").toggleClass("show");
        setTimeout(function () {
          $('#myPopup4').toggleClass('show');
        }, 2000)
      });

      $(".popup5").click(function () {
        $("#myPopup5").toggleClass("show");
        setTimeout(function () {
          $('#myPopup5').toggleClass('show');
        }, 2000)
      });

      $(".popup6").click(function () {
        $("#myPopup6").toggleClass("show");
        setTimeout(function () {
          $('#myPopup6').toggleClass('show');
        }, 2000)
      });

      $(".popup7").click(function () {
        $("#myPopup7").toggleClass("show");
        setTimeout(function () {
          $('#myPopup7').toggleClass('show');
        }, 2000)
      });

      $(".popup8").click(function () {
        $("#myPopup8").toggleClass("show");
        setTimeout(function () {
          $('#myPopup8').toggleClass('show');
        }, 2000)
      });

      $(".popup9").click(function () {
        $("#myPopup9").toggleClass("show");
        setTimeout(function () {
          $('#myPopup9').toggleClass('show');
        }, 2000)
      });
      $(".popup11").click(function () {
        $("#myPopup11").toggleClass("show");
        setTimeout(function () {
          $('#myPopup11').toggleClass('show');
        }, 2000)
      });
      $(".popup23").click(function () {
        $("#myPopup23").toggleClass("show");
        setTimeout(function () {
          $('#myPopup23').toggleClass('show');
        }, 2000)
      });

    });

    //localStorage.setItem("alertheader", JSON.stringify(this.props.alertNotificationEvents))

    $(document).ready(function () {
      $("#myBtn").click(function () {
        $("#mySidenav").toggleClass("opensidenav");
        $(".dropdown-container").hide();
      });
    });

    /*   $('body').on('click',function(event){
          if(!$(event.target).is('#myBtn')){
            $("#mySidenav").removeClass("opensidenav");
          }
       });
       */
    /* $('#mySidenav').click(function(event){
        event.stopPropagation();
    }); */


    var dropdown = document.getElementsByClassName("dropdown-btn");
    for (var i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function () {
        $(".dropdown-container").hide("active");

        this.classList.toggle("active");
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "none") {
          dropdownContent.style.display = "block";
        } else {
          dropdownContent.style.display = "none";
        }

      });
    }


    $('#return-to-top').click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 500);
    });

  }

  alerts = (key) => {
    if (this.state.alertNotificationEvents.length > 0) {
      this.props.history.push("/adminalert", {
        alertid: key,
        alerts: this.state.alertNotificationEvents,
      })
    }
  }
  alert = () => {
    if (this.state.alertNotificationEvents.length > 0) {
      this.props.history.push("/adminalert", {
        alertid: 0,
        alerts: this.state.alertNotificationEvents,
      })
    }
  }
  notifications = () => {

    // this.props.history.push("/my-inbox")

    if (this.state.notificationEvents.length > 0) {
      this.props.history.push("/my-inbox", {
        alertid: 0,
        notification: this.state.notificationEvents
      })
    }
  }

  notification = (key) => {
    if (this.state.notificationEvents.length > 0) {
      this.props.history.push("/adminnotification", {
        alertid: key,
        notification: this.state.notificationEvents
      })
    }
  }

  signout = event => {
    event.preventDefault();
    this.setState({ loading: true });
    if (localStorage.getItem('login') !== null) {
      var data = {
        userName: JSON.parse(localStorage.getItem('login'))["userName"],
        userId: JSON.parse(localStorage.getItem('login'))["userId"]
      };
      LoginService.LogoutUser(data, false, res => {
        if (res.status === "success") {
          // let subscribe = localStorage.getItem('nwesLetterEmailId')
          localStorage.clear();
          // if (subscribe) {
          //   localStorage.setItem('nwesLetterEmailId', 'SUBSCRIBED')
          // }
          localStorage.setItem("windowloggedout", true)
          window.location.href = "/";
        } else {
          warning(res.reasonText, warningNotification);
        }
      });
    } else {
      window.location.href = '/';
    }
  };


  productl = () => {
    this.props.history.push("/course-list")
    // ,
    // {
    //   alerts: this.props.alertNotificationEvents,
    //   notification: this.props.notificationEvents
    // }
    // )
  }

  learningpath = () => {
    this.props.history.push("/learning-path");
  }

  role = () => {
    this.props.history.push("/roles"
      // ,{
      //     alerts: this.props.alertNotificationEvents,
      //     notification: this.props.notificationEvents
      //   }
    )
  }
  gdp = () => {
    this.props.history.push("/gdpi")
  }
  adminuserlist = () => {
    this.props.history.push("/admin-user-list")
  }

  reportenro = () => {
    this.props.history.push("/report-enrollment")
  }

  myorde = () => {
    this.props.history.push("/my-orders")
  }
  adminuser = () => {

    this.props.history.push("/admin-user-list")
  }


  adminuserpro = () => {
    this.props.history.push("/admin-user-profile", {
      adminUser: this.props.adminUser
    })
  }

  adminusercourse = () => {
    this.props.history.push("/add-course")
  }

  adminnotifi = () => {
    this.props.history.push("/adminnotification")
  }
  adminale = () => {
    this.props.history.push("/adminalert")
  }
  sendemai = () => {
    this.props.history.push("/send-mail")
  }
  useractivity = () => {
    this.props.history.push("/user-activity")

  }
  assessmentReport = () => {
    this.props.history.push("/assessment-report")
  }
  unAssignedUsers = () => {
    this.props.history.push("/unassigned-users-report")
  }
  CamsApiIntegration=()=>{
    this.props.history.push("/uploadPAN")
  }
  feedBack = () => { this.props.history.push("/admin-feedback-report"); }
  getInTouch = () => { this.props.history.push("/admin-getintouch-report"); }
  render() {
    // var alertdata2 = [];
    var notealertdata2 = [];
    // var alertdata = JSON.parse(localStorage.getItem("AdminAlertNotification")) //JSON.parse(localStorage.getItem("alertdata"))
    // alertdata2 = JSON.parse(localStorage.getItem("AdminAlertNotification"))
    //notealertdata2 = JSON.parse(localStorage.getItem("AdminNotification"))
    //var notealertdata = JSON.parse(localStorage.getItem("AdminNotification"))
    notealertdata2 = this.state.notificationEvents
    var notealertdata = this.state.notificationEvents


    return (
      <React.Fragment>
        {/* <!------ header section ----------> */}
        <section className="header">
          <nav className="navbar navbar navbar-fixed-top">
            <span id="myBtn" className="sidenva-icon sidebartogglexyz">
              &#9776;
            </span>

            <div className="container-fluid">
              {/* <!-- Brand/logo --> */}
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#navbar"
                  aria-expanded="false"
                  aria-controls="navbar"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a className="navbar-brand" href="/admin-dashboard">
                  <img src={Finxlogo} alt="Finx Logo" />
                </a>
              </div>
              <div id="navbar" className="navbar-collapse collapse">
                <ul className="nav navbar-nav navbar-right">
                  {/* <li className="row">
                    <div className="right-search">
                    </div>
                  </li> */}
                  {/* <li className="nav-item dropdown notification">
                  <a
                      className="nav-link text-light popup11"
                      //href="#"
                      id="navbarDropdown"
                      role="button"
                      // data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      title="Alerts"
                    >
                      <i className="fa fa-bell-o" aria-hidden="true"></i>
                      <span class="popuptext" id="myPopup11">Coming Soon...</span>
                    </a> 
                    <a
                      className="nav-link text-light"
                      href="#"
                      className="notification"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      title="Alerts"
                    >
                      <i className="fa fa-bell-o"></i>
                    
                    </a> 
                   
                  </li>  */}
                  <li className="nav-item dropdown notification" id="user-option">
                    <a href
                      //onClick={this.signout}
                      className="nav-link text-light notification"
                      // className="notification"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      title="My Profile"
                    >
                      <i className="fa fa-user" aria-hidden="true"></i> Profile
                      {/* <span class="popup23 popuptext" id="">Coming Soon...</span> */}
                    </a>

                    <ul className="dropdown-menu" id="notification-profile" style={{ width: 'auto !important' }}>
                      <li className="notification-box">
                        <div className="row">
                          <div className="panel-body">
                            <div className="notification-content">
                              <div className="col-lg-12 col-sm-12 col-12">

                                <a href onClick={this.changepassword} className="text-light ">
                                  Change Password</a>

                                <a href onClick={this.signout} className="text-light alignRight" >
                                  Sign Out</a>

                                {/* <a onClick={e => {
                                  warning("Coming Soon", warningNotification
                                  )
                                }} className="text-light popup23">
                                  Ask For Help
                                     </a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown notification">
                    <a
                      className="nav-link text-light notification"
                      href
                      // className="notification"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      title="Messages"
                    >
                      <i className="fa fa-envelope-o" aria-hidden="true"></i> Messages 
                      {/* <span className="badge hidden-xs">                      
                        { (notealertdata || []).length ? (notealertdata || []).length : (notealertdata2 || []).length} 
                        </span>   */}
                    </a>
                    <ul className="dropdown-menu" id="notification-alert">
                      <li className="head text-light bg-dark">
                        <div className="row">
                          <div className="col-lg-12 col-sm-12 col-12">
                            <span>
                              {/* Messages ({(notealertdata || []).length}) */}
                              Messages ({this.state.inboxCount})
                              {/* Messages ({(notealertdata || []).length ? (notealertdata || []).length : (notealertdata2 || []).length}) */}
                            </span>
                            {/* <a href="" className="text-light pull-right">
                              Mark all as read
                            </a> */}
                          </div>
                        </div>
                      </li>
                      <li className="notification-box">
                        <div className="row">
                          <div className="panel-body">
                            <div className="col-lg-12 col-sm-12 col-12">
                              {this.state.flipalert === false ? notealertdata && notealertdata.slice(0, 3).map((item, key) =>
                              (
                                <div className="notification-content" id={item.notifyHistoryId} >
                                  <a href onClick={() => this.notification(item.notifyHistoryId)} >
                                    <div className="notification-body">
                                      {/* <strong className="text-info"> */}
                                      <strong className={"text-info " +(item.read_unread ? 'unread': '' )}>
                                      {item.fromEmailId}
                                      </strong>
                                      <div className="notification-body">
                                        {ReactHTMLParser(item.emailSubjectText)}
                                      </div>
                                      <small className="text-warning">{item.lastAttemptDatetime}</small>
                                    </div>
                                  </a>
                                </div>
                              )) : ""}

                              {this.state.flipalert === true ? notealertdata2 && notealertdata2.slice(0, 3).map((item, key) =>
                              (
                                <div className="notification-content" id={item.notifyHistoryId} >
                                  <a href onClick={() => this.notification(item.notifyHistoryId)} >
                                    <div className="notification-body">
                                      {/* <strong className="text-info"> */}
                                      <strong className={"text-info " +(item.read_unread ? 'unread': '' )}>
                                      {item.fromEmailId}</strong>
                                      <div className="notification-body">
                                        {ReactHTMLParser(item.emailSubjectText)}
                                      </div>
                                      <small className="text-warning">{item.lastAttemptDatetime}</small>
                                    </div>
                                  </a>
                                </div>
                              )) : ""}

                            </div>

                          </div>
                        </div>
                      </li>
                      <li className="footer bg-dark text-center">
                        <a onClick={this.inbox} href className="text-light">
                          View All
                        </a>
                      </li>
                    </ul>
                  </li>

                  {/* <li className="nav-item dropdown notification">
                    <a
                      className="nav-link text-light notification popup5"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      title="Chat"
                    >
                      <i className="fa fa-comments-o" aria-hidden="true"></i>
                      <span class="popuptext" id="myPopup5">Coming Soon...</span>
                    </a>
                  </li> */}

                  {/* <li className="nav-item dropdown notification">
                    <Popover
                      isOpen={this.state.isPopoverOpen}
                      position={'bottom'}
                      content={(
                        <div className="box">
                          <h3 className="popoveHeader">Cooming Soon...</h3>
                        </div>
                      )}
                    >
                      <a
                        className="nav-link text-light notification"
                        title="Chat"
                        onClick={(e) => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
                      >
                        <i className="fa fa-comments-o" aria-hidden="true"></i>
                      </a>
                    </Popover>
                  </li> */}
                  {/* <li className="nav-item dropdown notification">
                    <a
                      className="nav-link text-light notification popup6"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      title="Setting"
                    >
                      <i className="fa fa-cogs" aria-hidden="true"></i>
                      <span class="popuptext" id="myPopup6">Coming Soon...</span>
                    </a>
                  </li> */}
                  <li className="nav-item dropdown notification">
                    <a href
                      onClick={this.signout}
                      className="nav-link text-light notification"
                      // className="notification"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      title="Sign-Out"
                    >
                      <i className="fa fa-sign-out" aria-hidden="true"></i> Sign Out
                    </a>
                  </li>
                </ul>
              </div>
              {/* <!--/.nav-collapse --> */}
            </div>
          </nav>
        </section>

        {/* <!--------------- Left Side navbar -------------> */}
        <div id="mySidenav" className="sidenav sidebartogglexyz">
          <a href="admin-dashboard" className="sidebartogglexyz">
            <i className="fa fa-pie-chart" aria-hidden="true"></i> Dashboard
          </a>
          {[2,"2"].includes(localStorage.getItem('roleId')) ?
                <div>
                  <a href onClick={this.CamsApiIntegration} className="sidebartogglexyz">
                    <i className="fa fa-edit sidebartogglexyz" aria-hidden="true"></i> CAMS Api Integration
                  </a>
                </div>
                : ''}
          <button className="dropdown-btn sidebartogglexyz">
            <i className="fa fa-leanpub sidebartogglexyz" aria-hidden="true"></i> Learning
            Management
            <i className="fa fa-caret-down sidebartogglexyz "></i>
          </button>

          <div className="dropdown-container sidebartogglexyz" >
            {/* <a class="popup sidebartogglexyz">Programs
            <span class="popuptext" id="myPopup">Coming Soon...</span>
            </a> */}
            {/* <a href="/product-list"> */}
            <a href onClick={this.productl} className="sidebartogglexyz">
              Course</a>
            {/* <a class="popup1 sidebartogglexyz">Masters
            <span class="popuptext" id="myPopup1">Coming Soon...</span>
            </a>

            <a className="popup2 sidebartogglexyz">Classroom Training
            <span class="popuptext" id="myPopup2">Coming Soon...</span>
            </a> */}
            {/* <a onClick={this.learningpath}>
              Learning Path</a>
            <a onClick={this.gdp} className="sidebartogglexyz">GDPI</a> */}
          </div>
          <button className="dropdown-btn sidebartogglexyz">
            <i className="fa fa-user sidebartogglexyz" aria-hidden="true"></i> Security
            Management
            <i className="fa fa-caret-down sidebartogglexyz"></i>
          </button>
          <div className="dropdown-container sidebartogglexyz">

            {/* <a  onClick={this.adminuser}>User</a> */}
            <a href="admin-user-list" className="sidebartogglexyz">User</a>
            {/* <a className="popup3 sidebartogglexyz">Groups
            <span class="popuptext" id="myPopup3">Coming Soon...</span>
            </a> */}
            <a href onClick={this.role} className="sidebartogglexyz">Role</a>
            {/* <a onClick={this.gdp} className="sidebartogglexyz">GDPI</a> */}
          </div>
          <button className="dropdown-btn sidebartogglexyz">
            <i className="fa fa-book sidebartogglexyz" aria-hidden="true"></i> Reports
            <i className="fa fa-caret-down sidebartogglexyz"></i>
          </button>
          <div className="dropdown-container sidebartogglexyz">
            <a href onClick={this.reportenro} className="sidebartogglexyz">Enrollment Report</a>
            <a href onClick={this.myorde} className="sidebartogglexyz">Payment Report</a>
            <a href onClick={this.useractivity} className="sidebartogglexyz">UserActivity Report</a>
            <a href onClick={this.sendemai} className="sidebartogglexyz">Send Email</a>
            <a href onClick={this.assessmentReport} className="sidebartogglexyz">Assessment Report</a>
            <a href onClick={this.unAssignedUsers} className="sidebartogglexyz">Unassigned Users Report</a>
            <a href onClick={this.feedBack} className="sidebartogglexyz">Feedback Report</a>
            <a href onClick={this.getInTouch} className="sidebartogglexyz">Get in Touch Report</a>

          </div>
          {/* <a onClick={this.adminnotifi}>
            <i className="fa fa-envelope-o" aria-hidden="true"></i> Messages
          </a> */}
          {/* <a className="popup4">
            <i className="fa fa-comments-o" aria-hidden="true"></i> Chat
            <span class="popuptext" id="myPopup4">Coming Soon...</span>
          </a> */}
          {/* <a onClick={this.adminale} className="sidebartogglexyz">
            <i className="fa fa-bell-o" aria-hidden="true"></i> Alert
          </a> */}
        </div>

        {/* <!----------- Nav Heading---------------> */}
        <div className="header-shortcut-links navbar-fixed-top">
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div className="shortcut-links pull-right">
              <ul className="list-inline">
                {/* <li>
                  <a
                    onClick={this.adminuserpro}

                    title="New User"
                  >
                    <i className="fa fa-users" aria-hidden="true"></i>{" "}
                  </a>
                </li> */}
                <li>
                  <a
                    // className="popup7"
                    onClick={this.adminusercourse}
                    href
                    title="New Course"
                  >
                    <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                    {/* <span class="popuptext" id="myPopup7">Coming Soon..</span> */}
                  </a>
                </li>
                <li>
                  <a
                    className="popup8"
                    href
                    title="New Announcement"
                  >
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>
                    <span className="popuptext" id="myPopup8">Coming Soon...</span>
                  </a>
                </li>
                <li>
                  <a
                    className="popup9"
                    href
                    title="New events"
                  >
                    <i className="fa fa-calendar" aria-hidden="true"></i>
                    <span className="popuptext" id="myPopup9">Coming Soon...</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}

export default Adminheader;
