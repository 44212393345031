export const Global_var = {
    // BASEURL: "http://172.16.16.123:7000/thor-gateway-rcp",             // LOCAL
    // BASEURL: "https://uatservices.rcp.ciel.co.in/thor-gateway-rcp",     // UAT 
    BASEURL: "https://rcp-services.ciel.co.in/thor-gateway-rcp",      // PRODUCTION
    // BASEURL: "https://rcpmicro.njtechdesk.com/thor-gateway-rcp",     // Dev

    // Deployment
    URL_DEPLOYMENT: "/rcp-fc-admin/api/v1/deploymentFlag",

    URL_JWT_TRUST: "/rcp-thor-token-management/api/v1/jwt-token",
    URL_LOGIN: "/rcp-thor-authentication/api/v1/login",
    URL_LOGIN_MOBILE: "/rcp-thor-authentication/api/v1/loginWithMobile",

    URL_Session_DETAILS: "/rcp-thor-authentication/api/v1/party/getbyEmail", //?email=sk9@gmail.com
    URL_LOGOUT: "/rcp-thor-authentication/api/v1/logout",
    URL_UPDATE_FLAG: "/rcp-fc-learner-dashboard/api/v1/learnerDashboard/updateFlag",

    URL_FORGOTPASSWORD: "/rcp-thor-authentication/api/v1/user/forgotPassword",
    URL_CHANGEPASSWORD: "/rcp-thor-authentication/api/v1/user/changePassword",
    URL_UPDATEFORGOTPASSWORD: "/rcp-thor-authentication/api/v1/user/updateForgotPassword",
    URL_GET_ENCRYPTED_STRING: "/rcp-fc-learner-dashboard/api/v1/encryptedString",

    // ProductList
    URL_PRODUCT_ALL: "/rcp-fc-product/api/v1/product/allproduct",
    URL_PRODUCT_BYNAME: "/rcp-fc-product/api/v1/product/productName", //?productName=CFX
    URL_PRODUCT_BYID: "/rcp-fc-product/api/v1/product", //?productName=CFX
    URL_PRODUCT_BY_PARTYID: "/rcp-fc-learner-dashboard/api/v1/learnerDashboard/getLearnerAssessmentWithProduc" +
            "t", //?learnerPartyId = 10
    URL_PRODUCT_BY_PARTYID_NEW: "/rcp-fc-learner/api/v1/getLearnerAssessmentWithProduct",
    URL_ADD_PRODUCT: "/rcp-fc-product/api/v1/product",
    URL_GETPARTNER: "/rcp-fc-product/api/v1/patner",
    URL_UPDATEPRODUCT: "/rcp-fc-product/api/v1/product",
    URL_UPDATEISACTIVEINACTIVE: "/rcp-fc-product/api/v1/product",

    URL_ADD_PRODUCT_THUMBNAIL_VIDEO: "/rcp-thor-documentmanagement/api/v1/document",

    // Modules
    URL_ADD_MODULES: "/rcp-fc-product/api/v1/module",
    URL_GETMODULES: "/rcp-fc-product/api/v1/module/allmodule",
    URL_GETMODULESBYID: "/rcp-fc-product/api/v1/module",
    URL_UPDATEMODULES: "/rcp-fc-product/api/v1/module",
    URL_UPDATEISACTIVEINACTIVEMODULE: "/rcp-fc-product/api/v1/module",
    URL_PAN_DETAILS: "/rcp-fc-learner/api/v1/getPanDetails",
    URL_EUIN_DETAILS: "/rcp-fc-learner/api/v1/getEuinDetails",
    URL_RENEWAL_DETAILS:"/rcp-fc-learner/api/v1/getEuinRenewalDetails",
    URL_FALSE_POSITIVE_EMAIL: "/rcp-fc-learner/api/v1/validate/email",
    URL_OTP_EMAIL: "/rcp-fc-learner-dashboard/api/v1/learnerDashboard/_sendOTPMail",
    URL_VALIDATE_OTP_EMAIL: "/rcp-fc-learner-dashboard/api/v1/learnerDashboard/validateEmailOtp",
    URL_AUTHENTICATE_EMAIL: "/rcp-fc-learner-dashboard/api/v1/learnerDashboard/Validate/email/patterns",

    // LearningPath
    URL_GETLEARNINGPATHBYID: "/rcp-fc-product/api/v1/prerequisite/getProductPreRequisite",
    //URL_GETLEARNINGPATH: "/rcp-fc-product/api/v1/prerequisite/allpath",
    URL_GETLEARNINGPATH: "/rcp-fc-product/api/v1/prerequisite/count",
    URL_GETLEARNINGAllPRODUCT: "/rcp-fc-product/api/v1/prerequisite",
    URL_ADD_LEARNINGPATH: "/rcp-fc-product/api/v1/prerequisite",
    URL_UPDATELEARNINGPATH: "/rcp-fc-product/api/v1/prerequisite",
    URL_PARENTPRODUCTID: "/rcp-fc-product/api/v1/prerequisite/getProductPreRequisite",
    URL_PARENTPRODUCTIDASSIGNUSER: "/rcp-fc-learner/api/v1/learnerassessment",

    //OTP Service
    URL_SENDOTP: "/rcp-thor-otp/api/v1/otp",
    //'/api/v1/otp',//?mobileNo=9307933259&countryCode=91
    URL_OTP_VALIDATE: "/rcp-thor-otp/api/v1/otp/validate",
    //?mobileNo=9307933259&countryCode=91&otp=508769

    URL_FC_REGISTER_USER: "/rcp-fc-registration/api/v1/register/rcp",
    URL_AH_REGISTER_USER: "/rcp-fc-registration/api/v1/register/assesshub",
    URL_AH_ACTIVATE_TEST_USER: "/rcp-fc-learner/api/v1/assessment/test/_initiate",
    URL_AH_REGISTER_USER_BULK: "/rcp-fc-registration/api/v1/register/assessment/bulk",
    URL_AH_CREDENTIAL_USER: "/rcp-fc-learner/api/v1/learnerprovidercredetails",
    URL_ASSIGN_COURSE: "/rcp-fc-registration/api/v1/register/assesshub/assignCource",
    URL_USER_ASSESSHUB: "/rcp-fc-registration/hub/",
    URL_AH_CREDENTIAL_LOGIN: "https://www.assesshub.com/user/finxlogin.html?uname=", // r/Sqb13tW+6im2uKyz1VCfyuG0sqnh9FYj+MXnY6FFk=
    URL_GCUBE_CREDENTIAL_LOGIN: "https://lms.finxpert.org/Default.aspx?usertoken=", // r/Sqb13tW+6im2uKyz1VCfyuG0sqnh9FYj+MXnY6FFk=
    URL_GCUBE_CREDENTIAL_LOGIN1: "https://lms.finxpert.org/Default.aspx?usertoken=",

    URL_MOBILE_URL: "/rcp-fc-learner/api/v1/learner/validatemobile",
    URL_ORDER_INSERT: "/rcp-fc-payment/api/v1/order",
    URL_DO_Payment: "/rcp-fc-payment/Payment/doPayment",
    URL_USERDATAS: "/rcp-fc-learner/api/v1/learner/getLearners",
    URL_USERDATA: "/rcp-fc-learner/api/v1/learner",
    URL_USERADDRESS: "/rcp-thor-partyservices/api/v1/party/address",
    URL_UPDATEUSERPROFILE: "/rcp-fc-admin/api/v1/admin/updateuser",
    URL_UPDATEUSERPERSONALDATA:"/rcp-fc-admin/api/v1/admin/updatepersonaldetails",
    URL_UNLOCKUSERPROFILE:"/rcp-fc-admin/api/v1/admin/unloackProfile",
    URL_CHANGE_MANDATE_TYPE:"/rcp-fc-learner/api/v1/learner/changeMandateType",
    URL_UPLOADFILES: "/api/v1/document",
    URL_GETCOUNTRY: "/rcp-thor-regionmanagement/api/v1/country",
    URL_GETSTATE: "/rcp-thor-regionmanagement/api/v1/state",
    URL_GETCITY: "/rcp-thor-regionmanagement/api/v1/city",
    URL_GETADDRESSBYPIN: "/rcp-thor-regionmanagement/api/v1/area/_get",
    URL_GETLOOKUP_BY_CD_TYPE_NAME: "/rcp-thor-lookupmanagement/api/v1/codeValue/byName",
    URL_USERLIST_BY_PRODUCT_ID: "/rcp-fc-learner/api/v1/learner/unassignedusers",
    URL_GETLOOKUP_BY_REG_LOOKING_FOR: "/rcp-thor-lookupmanagement/api/v1/codeValue",
    URL_OTHER_COMPANY_WITH_ID: "/rcp-fc-learner/api/v1/getOthersCompanyWithId",
    URL_COMPANY_WITH_ID: "/rcp-fc-learner/api/v1/getCompanyWithId",
    URL_VALIDATE_EMAIL: "/rcp-fc-learner-dashboard/api/v1/learnerDashboard/emailValidate",
    URL_VALIDATE_OTHER_COMPANY: "/rcp-fc-learner/api/v1//otherCompany/validate",
    URL_USER_EMAIL_FLAG: "/rcp-fc-learner-dashboard/api/v1/learnerDashboard/getUserEmailFlag",
    URL_LEARNER_COMPANY: "/rcp-fc-learner/api/v1/getLearnerCompany",
    URL_MANDATE_BY_COMPANY_ID: "/rcp-fc-learner/api/v1/getCompanyMandate",
    URL_USER_ID: "/rcp-thor-usermanagement/api/v1/user",

    //GDPI
    URL_GDPI_LEARNER: "/rcp-fc-learner/api/v1/gdpi",
    URL_GDPI_BULKUPLOAD: "/rcp-fc-learner/api/v1/gdpi/gdpibulkupdate",

    //Admin Management
    URL_ADMIN_CREATE_USER: "/rcp-fc-admin/api/v1/admin/adduser",
    URL_FETCH_ADMIN_USER: "/rcp-thor-usermanagement/api/v1/user",
    URL_ADMIN_EDIT_USER: "/rcp-fc-admin/api/v1/admin/updateuser",
    URL_INACTIVE_ADMIN_USER: "/rcp-fc-admin/api/v1/admin/inactiveuser",
    URL_FETCH_ADMIN_USER_BY_ROLE_PARTY_ID: "/rcp-fc-admin/api/v1/admin/getuser",

    URL_BULK_USER_STATUS_CHANGE: '/rcp-fc-admin/api/v1/admin/inactiveuser',

    //Role Management
    URL_ROLES: "/rcp-thor-rolemanagement/api/v1/role",
    URL_ROLE_ACCESS_MANAGEMENT: "/rcp-thor-roleaccessmanagement/api/v1/access/_get",
    //Learner Dashboard
    URL_CALENDER_EVENTS: "/rcp-fc-learner-dashboard/api/v1/learnerDashboard/CalenderAndEvents",
    URL_ALERTS_NOTIFICATION_EVENTS: "/rcp-fc-learner-dashboard/api/v1/learnerDashboard/AlertsAndNotification",
    URL_PRODUCT_PRE_REQUISITE: "/rcp-fc-product/api/v1/prerequisite/getProductPreRequisite",
    URL_PRODUCT_ALL_EXCEPT_MAPPED: "/rcp-fc-product/api/v1/product/productlist",
    URL_NOTIFICATION_EVENTS: "/rcp-thor-notification/api/v1/queue/notification",
    URL_NOTIFICATION_EVENTS_INBOX_ALLMAIL:"/rcp-thor-notification/api/v1/queue/notification_inbox_all",
    URL_NOTIFICATION_EVENTS_CANDIDATE:"/rcp-thor-notification/api/v1/queue/candidate_message",
    URL_NOTIFICATION_EVENTS_SENT: "/rcp-thor-notification/api/v1/queue/send_notification",
    URL_NOTIFICATION_EVENTS_DRAFT: "/rcp-fc-learner/api/v1/mailNotify/getDraft",
    URL_GETTEMPLATE: "/rcp-fc-learner/api/v1/getTemplate",
    URL_NOTIFICATION_EVENTS_DRAFT_BY_ID: "/rcp-fc-learner/api/v1/mailNotify/getDraftById",
    URL_NOTIFICATION_UPDATE_DRAFT: "/rcp-fc-learner/api/v1/mailNotify/updateDraft",

    URL_ASSESSMENT_UPDATION: "/rcp-fc-learner/api/v1/learnerassessment/scoreupdate",
    URL_DISTRIBUTION_LIST: "/rcp-fc-learner/api/v1/learner/getStageWiseUser",
    URL_STAGE_LIST: "/rcp-fc-learner/api/v1/stage/getStage",
    URL_INBOX_MESSAGE_COUNT: "/rcp-thor-notification/api/v1/queue/getCount",
    URL_UPDATE_MESSAGE_COUNT: "/rcp-thor-notification/api/v1/queue/updateNotifyHistory",

    //Institute Name
    URL_INSTITUTE_LIST: "/rcp-fc-admin/api/v1/institute",

    //Generalinquiry
    URL_GENERALENQUIRY: "/rcp-fc-admin/api/v1/LearnerEnquiry",
    URL_ADMIN_SEND_EMAIL: "/rcp-fc-learner/api/v1/mailNotify/sendMail",
    //"/fc-admin/api/v1/mailNotify/sendMail", get Progress Overview Details
    URL_GET_PROGRESS_OVERVIEW: "/rcp-fc-learner-dashboard/api/v1/learnerDashboard/getProgressOverviewDetails",

    URL_BULK_UPLOAD: "/rcp-fc-learner/api/v1/learner/bulkupload",

    URL_BULK_UPLOAD_SCORE: "/rcp-fc-learner/api/v1/learnerBulk",
    URL_BULK_UPLOAD_NISM: "/rcp-fc-learner/api/v1/stage/wf_journy/updateNismProfileInBulk",
    URL_BULK_UPLOAD_WF_Update_To_Payment_Complete: "/rcp-fc-learner/api/v1/stage/wf_journy/updateWfToPaymentComplete",
    URL_BULK_UPLOAD_NISM_EXAM: "/rcp-fc-learner/api/v1/learner/bulkExamDateUpload",

    URL_MODULE_COURSE_PRD_PARTY: "/rcp-fc-learner-dashboard/api/v1/learnerDashboard/getModuleAgainstCourse",
    //?productID=20849&learnerPartyID=73671
    URL_MODULE_BY_PRODUCT: "/rcp-fc-product/api/v1/module",
    //? productID = 20849
    URL_LEARNER_ASSESSMENT_PREQUISITE: '/rcp-fc-learner/api/v1/learnerassessment/prerequisite',

    //get payment history for learner
    URL_LEARNER_PAYMENT_HISTORY: "/rcp-fc-payment/api/v1/order",
    // get learner enrollment
    URL_LEARNER_ENROLLMENT_HISTORY: "/rcp-fc-learner/api/v1/learner",
    URL_LEARNER_ROLE_USER_DETAILS: "/rcp-fc-learner/api/v1/learner/role/user_details/_get",

    // Chart service
    URL_REPORT_COURSE: "/rcp-fc-learner/api/v1/learnerreport/course",
    URL_REPORT_ENROLLMENT: "/rcp-fc-learner/api/v1/learnerreport/Enrollment",
    URL_REPORT_SESSION: "/rcp-thor-authentication/api/v1/sessionReport",

    // S3 BUCKET PATH
    URL_DOC_SAVE_PATH: "http://finxqa.s3.ap-south-1.amazonaws.com/",

    //UNBLOCKUSER
    URL_UNBLOCK_USER: "/rcp-thor-authentication/api/v1/user/updateblockeduser",

    //Check PAN Duplicate
    URL_Check_PAN: "/rcp-fc-learner/api/v1/learner/validateupdateuser",
    URL_Check_USER_REGISTRATION: "/rcp-fc-learner/api/v1/learner/validateuserForRegistration",

    //validater user email mobile pan aadhar while adding

    URL_Validate_New_User: "/rcp-fc-learner/api/v1/learner/validateuser",
    URL_Validate_LOGIN_User: "/rcp-fc-learner/api/v1/learner/validateuserforlogin",

    URL_USER_MANAGEMENT: "/rcp-thor-usermanagement/api/v1/user/activity/report",

    URL_ASSESSMENT_REPORT: "/rcp-fc-learner/api/v1/learnerassessment",
    URL_CFX_ASSESSMENT_REPORT: "/rcp-fc-learner/api/v1/learnercfxreport/downloadcfxreport",

    URL_CREATED_BY: "/rcp-thor-usermanagement/api/v1/user",
    URL_UNASSIGNED_USERS_REPORT: "/rcp-fc-learner/api/v1/learner/unassignedusers",

    // updated by vaishnavi URL_GETDISTRIBUTOR:
    // 'http://192.168.12.113:9006/fc-learner/api/v1/learner/data', URL_CITY_BY_ID:
    // 'http://192.168.12.113:9006/thor-regionmanagement/api/v1/city/_get'
    URL_GETAMCMASTER: '/rcp-fc-registration/api/v1/register/amc/_get',
    URL_GETDISTRIBUTOR: '/rcp-fc-learner/api/v1/learner/data',
    URL_GET_ALL_DISTRIBUTOR_LIST: '/rcp-fc-learner/api/v1/learner/distributorList',
    URL_CITY_BY_ID: '/rcp-thor-regionmanagement/api/v1/city/_get',
    URL_PDF_EXCEL_DATA: '/rcp-fc-learner/api/v1/learner/pdfExcelData',
    URL_GET_EXPERIENCE: '/rcp-fc-learner/api/v1/getExperience',
    URL_GET_FIELD_ACTIVE_FLAG: '/rcp-fc-learner/api/v1/learner/getFieldActiveFlag',
    // Send Email
    URL_SEND_EMAIL: "/rcp-fc-learner/api/v1/learner/send/email",
    URL_ADD_NEWSLETTER: "/rcp-fc-admin/api/v1/subscription/saveSubScription",

    // PRE Assessment
    PREASSESSMENT_TERMS_CONDITION: "/rcp-fc-learner/api/v1/assessment/term_condition/_get",
    PREASSESSMENT_TEST_INSTRUCTION: "/rcp-fc-learner/api/v1/assessment/instruction/_get",
    PREASSESSMENT_TEST_QUESTION_OPTION: "/rcp-fc-learner/api/v1/assessment/test_questionaire/_get",
    PREASSESSMENT_TEST_TEMS_UPDATE: "/rcp-fc-learner/api/v1/assessment/term_condition/_update",
    PREASSESSMENT_TEST_SCORE_UPDATE: "/rcp-fc-learner/api/v1/assessment/test_score/_update",

    URL_REATTEMPT_TEST: "/rcp-fc-learner/api/v1/assessment/test/_initiate",
    URL_GET_ATTEMPTED_LEARNER: "/rcp-fc-learner/api/v1/assessment/list/_get",
    URL_WORKFLOW_SEACH: "/rcp-thor-workflowservices/api/v1/workflow/_search",
    URL_WORKFLOW_PROCESS: "/rcp-thor-workflowservices/api/v1/process/_search",
    URL_GETFUNEL_STATUS_COUNT: "/rcp-fc-learner/api/v1/stage/stage_count/_get",
    URL_WORKFLOW_STATUS_UPDATE: "/rcp-fc-learner/api/v1/stage/wf_journy/_update",
    URL_SEND_MAIL: "/rcp-fc-learner/api/v1/mail/_send",
    URL_SEND_MAIL_KYD: "/rcp-fc-learner/api/v1/mail/_sendMail",
    URL_CENTER: "/rcp-fc-learner-dashboard/api/v1/NISM_Location/get",
    URL_WORKFLOW_UPDATE: "/rcp-thor-workflowservices/api/v1/process/_update",
    URL_GET_DATE_LOCATION: "/rcp-thor-workflowservices/api/v1//process/_get",
    URL_CERTIFICATE_DOWNLOAD: "/rcp-fc-learner-dashboard/api/v1/CertificatePDF_generator",
    URL_DOCUMENT_DOWNLOAD: "/rcp-thor-documentmanagement/api/v1/document/publicurl",
    URL_WORKFLOW_TRANSITION: "/rcp-thor-workflowservices/api/v1/process/_transition",
    URL_GETJOB: "/rcp-fc-learner/api/v1/getJob",
    URL_GETCIELREPORT: "/rcp-fc-learner/api/v1/learnerreport",
    URL_GETEMPANELMENTREPORT: "/rcp-fc-learner/api/v1/learner/getLearnerWiseAmc",
    URL_GET_MANDATE_REPORT: "/rcp-fc-learner/api/v1/mandateReport",
    URL_GET_NOT_REGISTERED_REPORT: "/rcp-fc-learner/api/v1/notRegisteredUsers",
    URL_KYD_MAIL: "/rcp-fc-learner/api/v1/mail/_sendMailToDistributer",
    URL_SEND_SMS: "/rcp-fc-learner/api/v1/sms/_sendSms",
    URL_COMPANY_LIST: "/rcp-fc-learner/api/v1/getCompany",
    URL_BULK_NIGO_UPLOAD: "/rcp-fc-admin/api/v1/nigo",
    URL_CAMS_PAN_UPLOAD: "/rcp-fc-admin/api/stub",
    URL_COMPANY_BY_ID: "/rcp-fc-learner/api/v1/getCompanyWithId",
    URL_GET_USER_DETAILS: "/rcp-fc-learner/api/v1/getUserDetails",
    URL_GET_USER_MANDATE_DETAILS: "/rcp-fc-learner/api/v1/getUserMandate",
    URL_GET_MANDATE_COMPONENTS: "/rcp-fc-learner/api/v1/getMandateComponents",
    URL_GET_PRODUCT_BY_IDS: "/rcp-fc-product/api/v1/product/productlistWithId",
    URL_SAVE_MANDATE: "/rcp-fc-learner/api/v1/saveMandateDetails",
    URL_SAVE_USER: "/rcp-fc-learner/api/v1/saveUser",
    URL_SAVE_RCP_LEARNER_ASSESSMENT: "/rcp-fc-learner/api/v1/learnerassessment/rcp-learnerassessment",
    URL_SEND_NO_MANDATE_EMAIL: "/rcp-fc-learner/api/v1/Mandate/cielOpsMail",
    URL_SEND_USER_MANDATE_EMAIL_SUCCESS: "/rcp-fc-learner/api/v1/Mandate/userMail",
    URL_LEARNER_MANDATE_DETAILS: "/rcp-fc-learner/api/v1/learnerMandateDetails",
    URL_CONFIRM_SUBMISSION: "/rcp-fc-learner/api/v1/learnerassessment",
    URL_GET_AUDIT_DETAILS: "/rcp-fc-learner/api/v1/getStatus",
    URL_GET_MANDATE_BY_ID: "/rcp-fc-learner/api/v1/getMandate",
    URL_CHECK_IN_MANDATE: "/rcp-fc-learner/api/v1/otherCompany/checkInMandate",
    URL_CHECK_MANDATE_DETAILS: "/rcp-fc-learner/api/v1/otherCompany/checkMandateDetails",
    URL_GET_LEARNER_SUBSCRIBED_PRODUCT: "/rcp-fc-learner/api/v1/getLearnerSubscribedProduct",
    URL_MANDATE_USER_EMAIL: "/rcp-fc-learner/api/v1/Mandate/userMail",
    URL_MANDATE_USER_SMS:"/rcp-fc-learner/api/v1/Mandate/userSMS",
    URL_NISM_USER_EMAIL: "/rcp-fc-learner/api/v1/Mandate/userMail",
    URL_NISM_USER_SMS:"/rcp-fc-learner/api/v1/Mandate/userSMS",
    URL_ZIP: "/rcp-fc-learner/api/v1/learner/zip",
    URL_BULK_ZIP:"/rcp-fc-learner/api/v1/learner/zipBulk",
    URL_NOMINEE_CREATE: "/rcp-fc-learner/api/v1/learner/NominiDetails",
    URL_ACCEPT_KYD: "/rcp-fc-learner/api/v1/learner/amc",
    URL_ARN_PDF_GENERATOR: "/rcp-fc-learner-dashboard/api/v1/ARNPDF_generator",
    URL_KYD_PDF_GENERATOR: "/rcp-fc-learner-dashboard/api/v1/KYDPDF_generator",
    URL_AMC_LIST_APPROVAL: "/rcp-fc-learner/api/v1/learner/Amc/Approved",
    URL_GETAMC_LIST: "/rcp-fc-learner/api/v1/learner/getAmc",
    URL_NOTIFICATION_EVENTS_SAVE_DRAFT: "/rcp-fc-learner/api/v1/mailNotify/saveDraftMail",
    URL_NOTIFICATION_EVENTS_DELETE_DRAFT: "/rcp-fc-learner/api/v1/mailNotify/deleteDraft",
    URL_NOTIFICATION_EVENTS_SEND_MAIL_TO_LEARNER: "/rcp-fc-learner/api/v1/mailNotify/sendMailSMSToLearner",
    URL_REGION_MANAGEMENT_BY_PIN: "/rcp-thor-regionmanagement/api/v1/pin",
    URL_SAVE_EUIN_DETAILS:"/rcp-fc-learner/api/v1/updateEuinDetails",
    URL_GET_APPROVED_AMC_LIST: "/rcp-fc-learner/api/v1/learner/getAprovedAmc",
    URL_UPDATE_USER_MANDATE: "/rcp-fc-learner/api/v1/learner/updateBank",
    //Renewal api
    URL_GET_NISM_VALIDITY_CHECK: "/rcp-fc-learner/api/v1/checkNismValidity",
    URL_SET_NISM_RENEW_FLAG: "/rcp-fc-learner/api/v1/setNismRenewalflag",
    URL_UPDATE_USER_WF_JOURNEY:"/rcp-fc-learner/api/v1/updateUserWfJourney",

    //feedback
    URL_POSTFEEDBACK: "/rcp-fc-registration/api/v1/register/savefeedback",
        //concernType List
    URL_CONCERN_TYPE_LIST:"/rcp-fc-registration/api/v1/register/feedback/getAllConcerns",
    URL_MANDATE_BANK_NAME_LIST:"/rcp-fc-learner/api/v1/getAllCompany",
    URL_FEEDBACK_REPORT_LIST:"/rcp-fc-learner/api/v1/report/feedback",
    URL_GETINTOUCH_REPORT_LIST:"/rcp-fc-learner/api/v1/report/getInTouch",
    URL_GET_LEARNER_PROGRESS:"/rcp-fc-schedular/api/v1/learnerprogress/getLearningProgress",
    URL_NOTIFICATION_DOCUMENT_DOWNLOAD:"/rcp-thor-notification/api/v1/queue/getDocumentUrl",
    //  CONST_BUCKET_NAME: "finx-bucket-tag"
    CONST_BUCKET_NAME: "rcp-bucket-tag",     // enable this for prod build
    //bucketName --    rcp-prod
};

